export default {
  standard: function (denomination) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: denomination || 'USD'
    });
  },

  compact: function (denomination) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      notation: 'compact',
      currency: denomination || 'USD'
    });
  },

  onlyDollars: function () {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    });
  }
};
